import work1 from '../../assets/work1.jpg'
import work2 from '../../assets/work2.png'
import work3 from '../../assets/work3.png'
import work4 from '../../assets/work4.png'
import work5 from '../../assets/work5.png'
import work6 from '../../assets/work6.png'

export const projectsData = [
    {
      id: 1,
      image: work1,
      title: "User Repository",
      category: "web",
      url: "https://github.com/SuhailSherief/UserAPI",
    },
    {
      id: 2,
      image: work2,
      title: "Vendor Service Portal",
      category: "web",
      url: "https://github.com/SuhailSherief/Genesis",
    },
    {
      id: 3,
      image: work3,
      title: "Opencore x260",
      category: "others",
      url: "https://github.com/SuhailSherief/ThinkPad-x260-macOS-OpenCore",
    },
    {
      id: 4,
      image: work4,
      title: "Twitter Clone",
      category: "web",
      url: "https://github.com/SuhailSherief/twitter_clone",
    },
    {
      id: 5,
      image: work5,
      title: "Paradox",
      category: "design",
      url: "https://github.com/SuhailSherief/boilerplate-chat_room",
    },
    {
      id: 6,
      image: work6,
      title: "Genesis",
      category: "others",
      url: "#",
    },
  ];
  
export const projectsNav = [
    {
        name: 'all'
    },
    {
        name: 'web'
    },
    {
        name: 'design'
    },
    {
        name: 'others'
    }
]