import React from "react";

function Frontend() {
  return (
    <div className="skills__content">
      <h2 className="skills__title">Frontend Developer</h2>
      <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">HTML</h3>
                    <span className="skills__level">Basic</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">CSS</h3>
                    <span className="skills__level">Basic</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">JavaScript</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
            </div>
        </div>
        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Tailwind</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">ReactJS</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">VueJS</h3>
                    <span className="skills__level">Basic</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Flutter</h3>
                    <span className="skills__level">Basic</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Frontend;
