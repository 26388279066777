import React from 'react'

function Social() {
  return (
    <div className="home__social">
        <a href="https://github.com/SuhailSherief/" className="home__social-icon"><i className="uil uil-github-alt"></i></a>
        <a href="https://www.instagram.com/suhail_sherief_/" className="home__social-icon"><i className="uil uil-instagram"></i></a>
        <a href="https://twitter.com/suhail_sherief" className="home__social-icon"><i className="uil uil-twitter-alt"></i></a>
    </div>
  )
}

export default Social